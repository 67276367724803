import React from "react"
import { Container, Layout, SEO, Grid, TextRow, ImageRow, ExternalLink, Tag } from "../../components";

import example from '../../images/projects/video-communication.png';

const VideoCommunication = () => (
  <Layout hideProfile>
    <SEO title="Project: Video Communication Service (WebRTC)" />
    <Container>
      <Grid>
        <TextRow>
          <h1>Video Communication Service (WebRTC)</h1>

          <Tag>React</Tag>
          <Tag>Node</Tag>
          <Tag>WebRTC</Tag>
          <Tag>Web Socket</Tag>
          <Tag>Saas</Tag>
          <Tag>Webpack</Tag>
          <Tag>Babel</Tag>

          <p>
            <strong>Sexlog</strong> is the largest social network adult content in Brazil.
            For being a social network, we had a lot of challenges related to usability and feature to connect our users.
          </p>
          <p>
            The Video Communication project was one of this features, in order to connect our users and allow them make calls without leaving the platform.
          </p>
          <p>
            The project was build using <strong>ReactJS</strong> and <strong>SAAS</strong>, besides that we had a great challenge in learn about <strong>WebRTC</strong> (Web Real-Time Communication).
            I performed as one of <strong>Front-end engineers</strong>.
          </p>
          <p>
            Click on the link below and create an account to see the result.
          </p>
          <ExternalLink href="https://sexlog.com" target="_blank" rel="noopener noreferrer">Sexlog</ExternalLink>

        </TextRow>

        <ImageRow>
          <img alt="Video Communication example" src={example} />
        </ImageRow>

      </Grid>
    </Container>
  </Layout>
);

export default VideoCommunication;
